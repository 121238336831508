// @ts-nocheck
import logoSogou from './images/sogou.png'
import logoSohushipin from './images/sohushipin.png'
import logoSohuqiche from './images/sohuqiche.png'
import logoHost from './images/host.png'
import logoNuanshi from './images/nuanshi.png'
import logoXinbang from './images/xinbang.png'
import logoChangyan from './images/changyan.png'
import logoShejidiguo from './images/shejidiguo.png'
import logoBiaodandashi from './images/biaodandashi.png'
import logoJifeng from './images/jifeng.png'
import logoDianjing from './images/dianjing.png'
import logoZhichi from './images/zhichi.png'
import logo68design from './images/68design.png'
import logoZhongguoshejiwang from './images/zhonguoshejiwang.png'
import logoTengxunyun from './images/tengxunyun.png'
import logoGaode from './images/gaode.png'
import logoSogousousuo from './images/sogousousuo.png'
import logoBaimi from './images/baimishenghuo.png'
import logoShejipi from './images/shejipi.png'
import logo36kr from './images/36kr.png'
// import logoXiaomayi from './images/xiaomayi.png';
import logoKuaikeduo from './images/kuaikeduo.jpg'

export const logoData = [
  {
    alt: '搜狗',
    src: logoSogou,
    link: 'https://www.sogou.com/'
  },
  {
    alt: '搜狐视频',
    src: logoSohushipin,
    link: 'https://tv.sohu.com/'
  },
  {
    alt: '搜狐汽车',
    src: logoSohuqiche,
    link: 'https://auto.sohu.com/'
  },
  {
    alt: '腾讯云',
    src: logoTengxunyun,
    link: 'https://cloud.tencent.com/'
  },
  {
    alt: '高德地图',
    src: logoGaode,
    link: 'https://ditu.amap.com/'
  },
  {
    alt: '搜狗搜索',
    src: logoSogousousuo,
    link: 'http://zhanzhang.sogou.com/'
  },
  {
    alt: '36kr',
    src: logo36kr,
    link: 'http://36kr.com/'
  },
  {
    alt: '设计帝国',
    src: logoShejidiguo,
    link: 'http://www.warting.com/'
  },
  {
    alt: '表单大师',
    src: logoBiaodandashi,
    link: 'https://www.jsform.com/'
  },
  {
    alt: '机锋',
    src: logoJifeng,
    link: 'http://www.gfan.com/'
  },
  {
    alt: '360点睛实效平台',
    src: logoDianjing,
    link: 'http://e.360.cn/'
  },
  {
    alt: '智齿客服',
    src: logoZhichi,
    link: 'http://www.sobot.com/'
  },
  {
    alt: '68Design',
    src: logo68design,
    link: 'http://www.68design.net/'
  },
  {
    alt: '中国设计网',
    src: logoZhongguoshejiwang,
    link: 'http://www.cndesign.com/'
  },
  {
    alt: '侯斯特',
    src: logoHost,
    link: 'http://weixinhost.com/'
  },
  {
    alt: '暖石',
    src: logoNuanshi,
    link: 'https://www.nuanshi100.com/'
  },
  {
    alt: '新榜',
    src: logoXinbang,
    link: 'https://www.newrank.cn/'
  },
  {
    alt: '百米生活',
    src: logoBaimi,
    link: 'http://www.100msh.net/'
  },
  {
    alt: '设计癖',
    src: logoShejipi,
    link: 'http://www.shejipi.com/'
  },
  {
    alt: '畅言云评',
    src: logoChangyan,
    link: 'http://changyan.kuaizhan.com/'
  },
  {
    alt: '快客多',
    src: logoKuaikeduo,
    link: 'http://kuaikeduo.kuaizhan.com/'
  }
]
