import React from 'react'
import { useRecoilValue } from 'recoil';
import { atom_is_login } from '../recoil-store';
import { OFFICIAL_DESCRIPTIONS, btnStats, URL_WX_LIST, URL_WX_INTRODUCE, URL_LOGIN_WAP } from '../../../constants/kzHomepage'
import { OfficialAccountCard } from '../components'
import { Button } from '../../../components'
import { diffEndJump } from '../../../utils/tools'

const OfficialAccPanel: React.FC<{
  isInScreen: boolean
}> = ({
  isInScreen = false
}) => {

  const isLogin = useRecoilValue(atom_is_login);

  return (
    <div className='official-account-panel-children'>
      <div className='description-cards'>
        {
          OFFICIAL_DESCRIPTIONS.map((item, index) => (
            <div className={`description-card ${isInScreen ? 'animated fadeInUp' : ''}`} style={{ animationDuration: `${index > 1 ? `${1.5 + index * 0.3}s` : '1s'}` }}>
              <OfficialAccountCard
                key={index}
                title={item.title}
                descriptions={item.descriptions}
                iconStyle={item.iconStyle}
                imgUrl={item.imgUrl}
              />
            </div>
        ))
        }
      </div>
      <div className='opt-btn'>
        <Button type='primary' onClick={() => {
          // @ts-ignore
          if (window && window.xqui && window.xqui.useStat) {
            // @ts-ignore
            window.xqui.useStat({
              ...btnStats['officialAccount'],
              page: 'kz_homepage',
              action: 'click'
            })
          }
          diffEndJump({
            href: isLogin? URL_WX_LIST : URL_WX_INTRODUCE,
            // 字节审核，暂时跳转登录
            wapHref: URL_LOGIN_WAP
          })
        }}>立即使用</Button>
      </div>
    </div>
  )
}

export default OfficialAccPanel
