import React from 'react'
import Icon from '../Icon'
import './index.less'


const clsPrefix = 'kz-ui-modal'
const Modal: React.FC<{
  header?: string | React.ReactNode,
  visible: boolean,
  onClose: () => void,
  className?: string,
  headerStyle?: React.CSSProperties,
  style?: React.CSSProperties,
  wrapperStyle?: React.CSSProperties,
  contentStyle?: React.CSSProperties
}> = ({
  header,
  visible = false,
  onClose = () => null,
  className = '',
  headerStyle = {},
  style,
  children,
  wrapperStyle,
  contentStyle
}) => {
  function handleClose () {
    onClose()
  }
  return (
    <div className={`${clsPrefix} ${className} ${visible ? '' : `${clsPrefix}__invisible`}`} style={style}>
      <div className={`${clsPrefix}__mask`} onClick={handleClose} />
      <div className={`${clsPrefix}__wrapper`} style={wrapperStyle}>
        <div className={`${clsPrefix}__header`} style={headerStyle}>
          {header}
          <div className='close-symbol' onClick={handleClose}>
            <Icon name='raw-error' style={{ fontSize: '20px' }} />
          </div>
        </div>
        <div className={`${clsPrefix}__content`} style={contentStyle}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default Modal
