import React, { useEffect, useRef, useState } from 'react'
import './index.less'

const isShow = false
export const IsShowContext = React.createContext(isShow)

const clsPrefix = 'c-panel-container'
const PanelContainer: React.FC<{
  title?: string,
  backgroundColor?: '#ffffff' | '#f9f9f9',
  children: (isShow: boolean) => React.ReactNode
  selector?: string
}> = ({
  title,
  children,
  backgroundColor = '#ffffff',
  selector = '.p-kz-homepage'
}) => {
  const panelEl = useRef(null)
  const [ isInScreen, setIsInScreen ] = useState(false)

  useEffect(() => {
    // @ts-ignore
    const container = document.querySelector(selector)
    container && container.addEventListener('scroll', addAnimate)

    // @ts-ignore
    const rect: { top: number } = panelEl.current && panelEl.current.getBoundingClientRect()
    // @ts-ignore
    if (rect.top > 84 && rect.top < window.innerHeight + 100) {
      setIsInScreen(true)
    } else {
      setIsInScreen(false)
    }

    // return container.removeEventListener('scroll', addAnimate)
  }, [])

  function addAnimate () {
    // @ts-ignore
    const container = document.querySelector(selector)

    // @ts-ignore
    if (panelEl.current && panelEl.current.offsetTop <= window.innerHeight + container.scrollTop - 84) {
      setIsInScreen(true)
    }
  }

  return (
    <div ref={panelEl} className={`${clsPrefix} ${isInScreen ? '' : 'initial-invisible'}`} style={{ backgroundColor }}>
      {title ? <div className={`${clsPrefix}__title ${isInScreen ? 'animated fadeIn' : ''}`}>{title}</div> : ''}
      {children(isInScreen) || ''}
    </div>
  )
}

export { PanelContainer }
