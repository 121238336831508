import * as React from "react";
import { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import "./index.less";
import kzLayout from "../../layout/kzLayout";
import { BannerCarousel, Modal, Footer, KsPromotion } from "../../components";
import { ServiceMenu, UsersPanel, UpdateItem } from "./components";
import { PanelContainer } from "../../components/PanelContainer";
import {
  serviceMenuLinks,
  URL_WX_LIST,
  URL_WX_INTRODUCE,
  URL_SITE_WAP,
  FOOTER_CONTENT,
  bannerStats,
  // WAP_HOST
} from "../../constants/kzHomepage";
// import BannerKzCloudImage from '../../assets/images/banner-kz-cloud.png';
import BannerDefaultImage from "../../assets/images/banner-default.png";
import BannerOffcAccImage from "../../assets/images/banner-official-account.png";
import PreBannerDefaultImage from "../../assets/images/pre-images/PC-banner1.jpg";
import WapBannerDefaultImage from "../../assets/images/pre-images/WAP-banner1.png";
import WapBannerOffcAccImage from "../../assets/images/pre-images/WAP-banner2.png";
import BannerKzFormImage from "../../assets/images/banner-kz-form.png";
import BannerKuaimaImage from "../../assets/images/banner-kuaima.png";

import { getUpdateNotice } from "../../request/kzHomepage";
import { SitePanel, OfficialAccPanel, MarketingPanel } from "./panels";
import { OurInfomation } from "./components/OurInfomation";
import { wapHostValue, atom_is_login, isKmNewUser } from "./recoil-store";

const clsPrefix = "p-kz-homepage";

interface NoticeItemProps {
  id: string | number;
  coverImg: string;
  title: string;
  publicDate: string;
  createTime: number;
  updateTime: number;
  content: string;
}
const KzHompage: React.FC<{}> = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [noticeList, setNoticeList] = useState<NoticeItemProps[]>([]);
  const [bannerData, setBannerData] = useState([
    {
      img: PreBannerDefaultImage,
      url: "/v3/workspace",
      wapUrl: URL_SITE_WAP,
    },
    {
      img: BannerOffcAccImage,
      url: URL_WX_LIST,
      wapUrl: null,
    },
    {
      img: BannerKzFormImage,
      url: "/v3/survey/list",
      wapUrl: null,
    },
    {
      img: BannerKuaimaImage,
      url: "https://kuaima.kuaizhan.com",
      wapUrl: null,
    },
  ]);

  const wapHost = useRecoilValue(wapHostValue);
  const isLogin = useRecoilValue(atom_is_login);
  const isNewKm = useRecoilValue(isKmNewUser);

  useEffect(() => {
    if (!isNewKm) {
        setBannerData(banners => {
            const _banners = [
                ...banners,
            ]
            const kmBanner = _banners.find(banner => banner.url === 'https://kuaima.kuaizhan.com')

            if (kmBanner) {
                kmBanner['url'] = 'https://kuaima.kuaizhan.com/v1/workspace'
            }
            return _banners
        }); 
    }
  }, [isNewKm])

  function adjustBanner() {
    if (window && (window.innerWidth < 1024 || window.screen.width < 1024)) {
      // setBannerImgList([WapBannerDefaultImage, WapBannerOffcAccImage])
      setBannerData([
        {
          img: WapBannerDefaultImage,
          url: "/v3/workspace",
          wapUrl: URL_SITE_WAP,
        },
        {
          img: WapBannerOffcAccImage,
          url: isLogin ? URL_WX_LIST : URL_WX_INTRODUCE,
          wapUrl: null,
        },
        {
          img: BannerKuaimaImage,
          url: "https://kuaima.kuaizhan.com",
          wapUrl: null,
        },
      ]);
    } else {
      const bannerDefault = new Image();
      bannerDefault.src = BannerDefaultImage;

      bannerDefault.onload = function() {
        if (bannerDefault.complete && (window.innerWidth >= 1024 || window.screen.width >= 1024)) {
          setBannerData([
            {
              img: BannerDefaultImage,
              url: "/v3/workspace",
              wapUrl: URL_SITE_WAP,
            },
            {
              img: BannerOffcAccImage,
              url: isLogin ? URL_WX_LIST : URL_WX_INTRODUCE,
              wapUrl: null,
            },
            {
              img: BannerKzFormImage,
              url: "/v3/survey/list",
              wapUrl: null,
            },
            {
              img: BannerKuaimaImage,
              url: "https://kuaima.kuaizhan.com",
              wapUrl: null,
            },
          ]);
        }
      };
    }
  }

  useEffect(() => {
    fetchUpdateNotice();
    adjustBanner();

    window.addEventListener("resize", adjustBanner);
    return () => window.removeEventListener("resize", adjustBanner);
  }, []);

  function fetchUpdateNotice() {
    // 获取上次最新的更新公告的时间
    let startTime: number | string = localStorage.getItem(
      "latest_update_notices_time"
    )
      ? Number(localStorage.getItem("latest_update_notices_time"))
      : 0;

    getUpdateNotice()
      .then((res) => {
        setNoticeList(res?.data?.notices || []);
        const newNotices =
          res?.data?.notices?.filter((item) => item.updateTime > startTime) ||
          [];
        if (newNotices?.length > 0) {
          if (window.screen.width < 1024) {
            return;
          }
          setIsModalVisible(true);
        }
      })
      .catch((err) => console.log(err, "err"));
  }

  function handleCloseModal() {
    setIsModalVisible(false);
    const newestTime = noticeList?.[0]?.updateTime;
    if (!newestTime) {
      return;
    }
    // 更新本次最新的更新公告的时间
    localStorage.setItem("latest_update_notices_time", String(newestTime));
  }

  return (
    <div className={clsPrefix}>
      <div className={`${clsPrefix}__screen ${clsPrefix}__screen-one`}>
        <div className="contents">
          <div className="service-menu">
            {/**字节小程序审核隐藏 */}
            {!(navigator.userAgent.toLowerCase().indexOf("toutiao") > -1) && (
              <ServiceMenu links={serviceMenuLinks(wapHost, isLogin, isNewKm)} />
            )}
          </div>
          <div className="carousel">
            <BannerCarousel
              imgList={bannerData}
              delay={7000}
              statPage="kz_homepage"
              bannerStats={bannerStats}
            />
          </div>
        </div>
      </div>

      <div className={`${clsPrefix}__screen ${clsPrefix}__screen-two`}>
        <PanelContainer title="零门槛建站工具">
          {(isShow) => <SitePanel isInScreen={isShow} />}
        </PanelContainer>
      </div>

      <div className={`${clsPrefix}__screen ${clsPrefix}__screen-three`}>
        <PanelContainer title="公众号" backgroundColor="#f9f9f9">
          {(isShow) => <OfficialAccPanel isInScreen={isShow} />}
        </PanelContainer>
      </div>
      {/* <div className={`${clsPrefix}__screen ${clsPrefix}__screen-four`}>
        <PanelContainer title='快客多：专注于会员的电商平台'>
          {(isShow) => <KkdPanel isInScreen={isShow} />}
        </PanelContainer>
      </div> */}
      <div className={`${clsPrefix}__screen ${clsPrefix}__screen-five`}>
        <PanelContainer title="营销互动" backgroundColor="#f9f9f9">
          {(isShow) => <MarketingPanel isInScreen={isShow} />}
        </PanelContainer>
      </div>
      <div className={`${clsPrefix}__screen ${clsPrefix}__screen-six`}>
        <PanelContainer title="用心做服务 成就行业口碑影响力">
          {(isShow) => <UsersPanel isInScreen={isShow} />}
        </PanelContainer>
      </div>

      <OurInfomation />

      <Footer
        contentBefore={FOOTER_CONTENT.contentBefore}
        linkUrl={FOOTER_CONTENT.linkUrl}
        linkContent={FOOTER_CONTENT.linkContent}
        contentAfter={FOOTER_CONTENT.contentAfter}
      />

      {/*快手推广，仅在kz.kuaizhan.com域名下展示*/}
      {
        window.location.href.indexOf("ks.kuaizhan.com") !== -1 && <KsPromotion />
      }

      {/* <Modal
        visible={isModalVisible}
        onClose={handleCloseModal}
        header="更新公告"
        headerStyle={{ background: "#207CD3", color: "#ffffff" }}
        className="notices-modal"
      >
        <div className="notices-modal__content">
          <div style={{ overflow: "auto" }}>
            {noticeList.map((item, index) => (
              <UpdateItem
                key={index}
                title={item.title}
                imgUrl={item.coverImg}
                publicTime={item.publicDate}
                description={item.content}
              />
            ))}
          </div>
        </div>
      </Modal> */}
    </div>
  );
};

// @ts-ignore
KzHompage.Layout = kzLayout;

export default KzHompage;
