import { atom, selector } from 'recoil';
export const wapHostValue = atom({
    key: 'wapHostValue',
    default: ''
})

export const userInfo = atom({
    key: 'userInfo',
    default: {} as { _id?: string, kz_mobile?: string },
})

export const atom_is_login = selector({
    key: 'isLogin',
    get: ({ get }) => {
        const curUserInfo = get(userInfo);
        if (curUserInfo?._id) {
            return true;
        }
        return false;
    }
})

export const isKmNewUser = atom({
    key: 'isNewKmUser',
    default: true,
})