import React from 'react'
import { AdvancedLinkProp } from '../../../../types/components'
import { serviceMenuStats } from '../../../../constants/kzHomepage'
import { diffEndJump } from '../../../../utils/tools'
import './index.less'

interface ServiceMenuItemProps extends AdvancedLinkProp {
  imgUrl?: string,
  tip?: string,
  text: string,
  wapHref?: string | null,
  disableInWx?: boolean,
}
const clsPrefix = 'c-service-menu'
const ServiceMenu: React.FC<{
  title?: string,
  links?: Array<ServiceMenuItemProps>,
  linksClassName?: string
}> = ({
  title = '产品服务',
  links = []
}) => {
  function handleLinkStat (index: number) {
    // @ts-ignore
    if (window && window.xqui && window.xqui.useStat) {
      // @ts-ignore
      window.xqui.useStat({
        ...serviceMenuStats[index],
        page: 'kz_homepage',
        action: 'click'
      })
    }
  }
  return (
    <div className={clsPrefix}>
      {
        title && <div className={`${clsPrefix}__title`}>{title}</div>
      }
      <div className={`${clsPrefix}__links`}>
      {
        links && links.map((link, index) => (
          <div
            className={`${clsPrefix}__links-item hover-grow`}
            onClick={() => {
              handleLinkStat(index)
              diffEndJump({ href: link.href, wapHref: link.wapHref })
            }}
          >
            {link.imgUrl && <img className='link-img' src={link.imgUrl} alt='-' />}
            <div className='link-text'>
              <div className='link-title'>
                {link.text}
              </div>
              <span className='link-tip'>{link.tip}</span>
            </div>
          </div>
        ))
      }
      </div>
    </div>
  )
}

export { ServiceMenu }
