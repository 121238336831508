import React from 'react'
import { logoData } from './logoData'
import './index.less'

const statData = [
  {
    number: 600,
    title: '注册用户',
    unit: '万'
  },
  {
    number: 900,
    title: '累计服务站点',
    unit: '万'
  },
  {
    number: 360,
    title: '覆盖行业',
    unit: '种'
  }
]

const clsPrefix = 'c-users-panel'
const UsersPanel: React.FC<{
  isInScreen: boolean
}> = ({
  isInScreen = false
}) => {
  // const isShow = useContext(IsShowContext)
  return (
    <div className={clsPrefix}>
      <div className={`${clsPrefix}__data`}>
        {
          statData.map((data, index) => (
            <div
              className={`data-item ${isInScreen ? 'animated fadeInUp' : ''}`}
              key={index}
              style={{ animationDuration: `${1.5 + index * 0.4}s` }}
            >
              <div className='data-number'>
                <span className='emphasis-num'>{data.number}</span>
                <span className='plus-symbol'>+</span>
                <span className='data-unit'>{data.unit}</span>
              </div>
              <div className='data-title'>{data.title}</div>
            </div>
          ))
        }
      </div>
      <div className={`${clsPrefix}__transitional-words ${isInScreen ? 'animated fadeIn' : ''}`} style={{ animationDuration: '1.5s' }}>
        <p className='emphasis-text'>他们都在用搜狐快站</p>
        <span className='shallow-text'>来自政府组织、互联网、媒体、教育等各个行业的用户</span>
        <span className='shallow-text'>以下为友情链接</span>
      </div>
      <div className={`${clsPrefix}__users`}>
        <ul className={`${clsPrefix}--ul ${isInScreen ? 'animated fadeInUp' : ''}`} style={{ animationDuration: '1.5s' }}>
          {
            logoData.map(item => (
              <li key={item.alt} className={`${clsPrefix}--li`}>
                <a href={item.link} target='_blank' rel='nofollow'>
                  <img
                    alt={item.alt}
                    src={item.src}
                    className={`${clsPrefix}--logo`}
                  />
                </a>
              </li>
            ))
          }
        </ul>
      </div>
    </div>
  )
}

export { UsersPanel }

