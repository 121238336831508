export const CY_LOGIN = '/login'
export const CY_REGISTER = '/register'
export const GET_USER_INFO = ''
export const CY_LOGOUT = '/logout'

// KZ Homepage
export const API_USER_INFO = '/club/apiv1/me'
export const API_FREE_TRIAL = '/platform/agent/api/v1/agent'
export const API_UPDATE_NOTICE = '/platform/api/v1/update/notice?bizType=SITE';
export const API_GET_WAP_HOST = 'https://www.kuaizhan.com/kzplatform/api/sites/getMobileSiteDomain';
export const API_IS_NEW_KM_USER = `/platform/api/v1/km/isNewKmUser`
// CY Homepage
export const API_GMT_NUM = '/getCmtNum/2?'
export const API_VCODE_GET = '/verifyCode'
export const API_LOGIN = '/loginAjax/2'
//export const API_CY_UPDATE_NOTICE = '/platform/api/v1/update/notice?bizType=CHANGYAN';
export const API_CY_UPDATE_NOTICE = '/platform/api/v1/update/notice?bizType=CHANGYAN';

export const API_CY_USER_INFO = '/loginInfo'

export const API_NEW_USER_INFO = '/kzplatform/api/user/userInfo'