import { API_USER_INFO, API_FREE_TRIAL, API_UPDATE_NOTICE, API_GET_WAP_HOST, API_IS_NEW_KM_USER } from './api'
import request from './request'

/**
 * 查询用户信息
 */
export function getUserInfo () {
  return request(API_USER_INFO, {
    method: 'GET'
  })
}

/**
 * 免费使用
 */
export function getFreeTrial (phone: string) {
  return request(API_FREE_TRIAL, {
    method: 'POST',
    formData: {
      phoneNo: phone,
      name: '快站 - 免费试用',
      agentType: 'GOLD'
    }
  })
}

/**
 * 获取更新信息
 */
export function getUpdateNotice () {
  return request(API_UPDATE_NOTICE, {
    method: 'GET'
  })
}

export function getWapHost() {
  return request(API_GET_WAP_HOST, {
    credentials: 'omit',
    mode: 'cors'
  })
}

/**
 * 获取是否是快码新用户
 */
export function getIsNewKMUser() {
    return request(API_IS_NEW_KM_USER, {
        
    })
}