import * as React from 'react';
import { useRecoilState } from 'recoil';
import { wapHostValue, userInfo, isKmNewUser } from '../../page/kz/recoil-store';
import { useState, useEffect } from 'react'
import '@/assets/common.less'
import '../../assets/styles/animate.css'
import { prompt } from '@kzui/core';
import contactQrcodeImg from '../../assets/images/km/customer-service.png'
import { KzLogo, Button, Drawer, AdvancedLink } from '../../components'
import { AdvancedLinkProp } from '../../types/components'
import LinksNav from '../../components/LayoutHeader/LinksNav'
import LayoutHeader from '../../components/LayoutHeader'
import OfflineAvatarImage from '../../assets/images/kz-offline-avatar.png'
import OnlineAvatarImage from '../../assets/images/kz-online-avatar.png'
import {
  URL_REGISTER_WAP,
  URL_REGISTER_PC,
  URL_LOGIN_WAP,
  URL_LOGIN_PC,
  URL_LOGOUT_PC,
  URL_LOGOUT_WAP,
  URL_SITE_WAP,
} from '../../constants/kzHomepage'
import { getIsNewKMUser, getUserInfo, getWapHost } from '../../request/kzHomepage'
import { diffEndJump } from '../../utils/tools'
import './index.less'

const navLinks = [
  // 备案需要
  // {
  //   link: '/information',
  //   title: '行业资讯',
  //   outerLink: true,
  //   isOpenNewWindow: true
  // },
  {
    link: '/v3/homepage/agent',
    title: '合作加盟',
    outerLink: true,
    isOpenNewWindow: true
  },
  {
    link: '/v3/homepage/pricing',
    title: '定价',
    outerLink: true,
    isOpenNewWindow: true
  },
  {
    link: '/v3/kz-cloud/my-apps',
    title: '快站云',
    outerLink: true,
    isOpenNewWindow: true
  },
  {
    link: '/v5/taobao',
    title: '淘宝客',
    outerLink: true,
    isOpenNewWindow: true
  },
  {
    link: 'https://kuaima.kuaizhan.com',
    title: '快码活码短链',
    outerLink: true,
    isOpenNewWindow: true
  },
  {
    link: 'https://www.kuaizhan.com/wx/mass-price',
    title: '公众号套餐',
    outerLink: true,
    isOpenNewWindow: true,
    isNew: true,
  },
]

const afterLoginLinks: AdvancedLinkProp[] = [
  {
    title: '进入工作台',
    href: '/v3/workspace',
    linkType: 'outer',
    isOpenNewWindow: true
  }
]

const clsPrefix = 'kz-layout'

const commonNode = (props: LayoutProps) => {
  const [ isDrawerVisible, setIsDrawerVisible ] = useState(false)
  const [ isLogin, setIsLogin ] = useState(false)
  const [ account, setAccount ] = useState()
  const [wapHost, setWapHost] = useRecoilState(wapHostValue);
  const [ _userInfo, setUserInfo ] = useRecoilState(userInfo)
  const [kmNew, setKmNew] = useRecoilState(isKmNewUser) // 是否是快码新用户

  useEffect(() => {
    getWapHost().then(res => {
      console.log(res, 'res');
      const domain = res?.data?.domain || 'i';
      setWapHost(`${domain}.kuaizhan.com`)
    })
  }, [])

  function useDidShow () {
    console.log('go back')
    fetchUserInfo()
  }

  function pvStat () {
    // 统计官网流量
    if (window) {
      // @ts-ignore
      const { kaq } = window
      // 用 kaq
      kaq('create', `plf-kz-homepage-${window.screen.width < 1024 ? 'wap' : 'pc'}`)
      kaq('set', 'pageType', 0)
      kaq('send', 'pageview')
      // 用 stat-portrait
      // @ts-ignore
      window.xqui.useStat({
        action: 'enter',
        category: '',
        bizType: 2,
        page: 'kz_homepage'
      })
    }
  }

  useEffect(() => {
      // 根据窗口宽度决定是否加 suspend-wiki
      if (window.screen.width >= 1024) {
        if (window.location.host !== 'www.kuaizhan.com' && !window.location.host.includes('localhost') ) {
          window.location.href = '//www.kuaizhan.com'
        }
        const script = document.createElement('script')
        script.src = '//cdn.kuaizhan.com/xiaoqiang/suspend-wiki.js'
        document.body.appendChild(script)
      } else {
        if (window.location.host === 'www.kuaizhan.com' && wapHost?.includes('.kuaizhan.com')) {
          window.location.href = `//${wapHost}`
        }
      }
  }, [wapHost])

  useEffect(() => {
    
    if (window) {
      // @ts-ignore 注册来源 要在跳转之前种
      if (window.xqui && window.xqui.setPermanentCk) {
        let refer = document.referrer || 'direct'

        // @ts-ignore
        window.xqui.setPermanentCk('kz_mes_info', refer)
      }
      // 防止返回不刷新
      window.addEventListener('pageshow', (e) => {
        if (e.persisted || (window.performance && window.performance.navigation.type === 2)) {
          useDidShow()
        }
      }, false)

      // 统计
      pvStat()
    }

    fetchUserInfo()

  }, [])

  useEffect(() => {
    if (isLogin && window.screen.width >= 1024) {
        getIsNewKMUser().then(data => {
            setKmNew(data?.isNewKmUser || false)
            if (!data?.isNewKmUser) {
                navLinks[4].link = 'https://kuaima.kuaizhan.com/v1/workspace'
            } else {
                navLinks[4].link = 'https://kuaima.kuaizhan.com'
            }
        })
    }
  }, [isLogin])
  
  console.log(kmNew, '是否是快码新用户')

  const drawerHeader = (
    <div className={`${clsPrefix}__drawer-header`}>
      <img className='b-user-avatar' src={isLogin ? OnlineAvatarImage : OfflineAvatarImage} alt='avatar' />
    </div>
  )

  function fetchUserInfo () {
    getUserInfo().then((data) => {
      setUserInfo(data)
      if (data && data._id) {
        setIsLogin(true);
      } else {
        setIsLogin(false);
      }
      if (data && (data.kz_mobile || data.nick || data.kz_email)) {
        setAccount(data.kz_mobile || data.nick || data.kz_email || '')
      }
      console.log(data,'user')
    }).catch((err) => {
      console.log(err, 'err')
      setIsLogin(false);
      setUserInfo({});
    })
  }

  function handleLogin () {
    // @ts-ignore
    if (window && window.xqui && window.xqui.useStat) {
      // @ts-ignore
      window.xqui.useStat({
        category: 'kz_site_click_login',
        page: 'kz_homepage',
        action: 'click',
        bizType: 2,
      })
    }
    diffEndJump({
      href: URL_LOGIN_PC,
      wapHref: URL_LOGIN_WAP,
      isOpenNewWindow: false
    })
  }

  function handleRegister () {
    // @ts-ignore
    if (window && window.xqui && window.xqui.useStat) {
      // @ts-ignore
      window.xqui.useStat({
        category: 'kz_site_click_register',
        page: 'kz_homepage',
        action: 'click',
        bizType: 2,
      })
    }
    diffEndJump({
      href: URL_REGISTER_PC,
      wapHref: URL_REGISTER_WAP,
      isOpenNewWindow: false
    })
  }

  function handleLogout () {
    diffEndJump({
      href: URL_LOGOUT_PC,
      wapHref: URL_LOGOUT_WAP,
      isOpenNewWindow: false
    })
  }

  function isToutiao() {
    return navigator.userAgent.toLowerCase().indexOf('toutiao') > -1
  }

  return (
    props.children
      ? (
        <div className={clsPrefix}>
          <LayoutHeader className={`${clsPrefix}__header`}>
            <div className={`${clsPrefix}__left`}>
              <KzLogo />
              <LinksNav
                navLinks={navLinks}
                className={`${clsPrefix}__nav`}
              />
              {/* <AdvancedLink className='intruction-entrance' href='/v5/taobao' linkType='outer' isOpenNewWindow>
                <img src={TbEntranceImage} className='intruction-entrance-img' />
              </AdvancedLink> */}
            </div>
            {!isToutiao() &&
            <div className={`${clsPrefix}__right`}>
              { isLogin ? (
                <div className='user-info'>
                  <span className='info-unit'>你好{`，${account}`}</span>
                  {
                    afterLoginLinks.map((item, index) => (
                      <AdvancedLink key={index} className='info-unit' {...item}>{item.title}</AdvancedLink>
                    ))
                  }
                  <span className='info-unit' onClick={handleLogout}>退出</span>
                </div>
              ) : (
                <div className='login-buttons'>
                  <Button
                    className='login-btn'
                    themeColor='#198ED9'
                    onClick={handleLogin}
                  >
                    登录
                  </Button>
                  <Button
                    className='register-btn'
                    type='primary'
                    themeColor='#198ED9'
                    onClick={handleRegister}
                  >立即注册</Button>
                </div>
              )}
              <div className='wap-menu' onClick={() => setIsDrawerVisible(visible => !visible)}>
                <div className='short-line' />
                <div className='long-line' />
                <div className='short-line' />
              </div>
            </div>}
          </LayoutHeader>
            {props.children}
            <Drawer
              visible={isDrawerVisible}
              title={drawerHeader}
              onClose={() => setIsDrawerVisible(false)}
              style={{ top: '50px' }}
              className={`${clsPrefix}__drawer`}
            >
              <a className='drawer-link' href='/'>首页</a>
              <a className='drawer-link' href={`//${wapHost}/v2/sites/`}>手机建站</a>
              {!isToutiao() && <a className='drawer-link' href={`//${wapHost}/v2/covers/?site_id=4316069638`}>快站动态</a>}
              {!isToutiao() && <a className='drawer-link' href={`//${wapHost}/clubv2/forums/W8g-qb58BAAQWYBl`}>快站分享</a>}
              <a className='drawer-link' href='tel://4001060025'>联系我们</a>
              {
                isLogin ? (
                  <Button
                    className='login-out-btn'
                    themeColor='#188ED8'
                    onClick={handleLogout}
                  >
                    退出登录
                  </Button>
                ) : ''
              }
            </Drawer>
            <AdvancedLink
              href={isLogin ? URL_SITE_WAP : URL_REGISTER_WAP}
              linkType='outer'
              className={`${clsPrefix}__site-create-btn`}
            >
              {
                isLogin ? '进入管理' : '免费注册'
              }
            </AdvancedLink>
        </div>
      ) : null
  )
}

interface LayoutProps {
  layoutData?: any,
  children?: any
}

const Layout: React.FC<LayoutProps> = (props: LayoutProps) => {
  return commonNode(props)
}

export default Layout
