import React, { useState } from 'react'
import { useRecoilValue } from 'recoil';
import { wapHostValue } from '../recoil-store';
import { SITE_DESCRIPTIONS, SITE_IMAGES, btnStats, URL_SITE, URL_SITE_WAP, siteDetailStat, URL_SITE_DETAIL, URL_SITE_DETAIL_WAP } from '../../../constants/kzHomepage'
import { SiteCard } from '../components'
import { Button } from '../../../components'
import { diffEndJump } from '../../../utils/tools'

const SitePanel: React.FC<{
  isInScreen: boolean;
}> = ({
  isInScreen = false,
}) => {
  const [cardIndex, setCardIndex] = useState(0)
  
    const wapHost = useRecoilValue(wapHostValue);

  return (
    <>
      <div className='site-panel-children'>
        <img className={`site-panel-image ${isInScreen ? 'animated fadeInLeft' : '' }`} src={SITE_IMAGES[cardIndex]} />
        <div className='site-cards'>
          {
            SITE_DESCRIPTIONS.map((item, index) => (
                <SiteCard
                  key={index}
                  title={item.title}
                  descriptions={item.descriptions}
                  onHover={() => setCardIndex(index)}
                  onWapClick={() => setCardIndex(index)}
                  index={index}
                />
            ))
          }
        </div>
      </div>
      <div className='opt-btn'>
        <Button
          type='default'
          onClick={() => {
            // @ts-ignore
            window.xqui.useStat({
              category: siteDetailStat.category,
              page: 'kz-homepage',
              bizType: 2,
              action: 'click'
            })
            console.log(URL_SITE_DETAIL_WAP(wapHost), '详情链接')
            diffEndJump({ href: URL_SITE_DETAIL, wapHref: URL_SITE_DETAIL_WAP(wapHost) })
          }}
        >
          详情介绍
        </Button>
        <Button type='primary' onClick={() => {
          // @ts-ignore
          if (window && window.xqui && window.xqui.useStat) {
            // @ts-ignore
            window.xqui.useStat({
              ...btnStats['site'],
              page: 'kz_homepage',
              action: 'click'
            })
          }
          diffEndJump({ href: URL_SITE, wapHref: URL_SITE_WAP })
        }}>
          免费创建
        </Button>
      </div>
    </>
  )
}

export default SitePanel
