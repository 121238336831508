import React from 'react'
import './index.less'
import KS_IMAGE from "../../../../assets/images/kz-hompage-icon/ks.png"


const clsPrefix = 'c-ks-promotion'

const KsPromotion: React.FC = ({}) => {
    return (
        <div className={clsPrefix}>
            {/*<div className={`${clsPrefix}__container`}>*/}
                <img src={KS_IMAGE} className={`${clsPrefix}__pic`} />
                <div className={`${clsPrefix}__content`}>
                    <div className={`${clsPrefix}__content-title`}>快手极速版</div>
                    <div className={`${clsPrefix}__content-desc`}>新人最高56元红包，当天可提现</div>
                </div>
                <div className={`${clsPrefix}__download-btn`} onClick={() => {
                    window.location.href = "https://nbicrzgg.yoymgmxg.com/nebula/ares/landpagey?cc=share_wxms&kpf=ANDROID_PHONE_H5&fid=1687071753&code=798368279&shareMethod=picture&kpn=NEBULA&subBiz=INVITE_CODE&shareId=16563777158882&shareToken=Y2QaAuQ91&platform=weixin&shareMode=app&spv=TzQIIEm9kytgJaoMBql%2Bp3cZVGSsTXXPsNuJLHdv8L7P4057IUSb6un6q25ZttLh&shareObjectId=798368279"
                }}>下载领取</div>
            {/*</div>*/}
        </div>
    )
}

export default KsPromotion