import React from 'react'
import { DescriptionItem } from '../../../../components/DescriptionItem'
import './index.less'

const clsPrefix = 'c-official-account-card'
const OfficialAccountCard: React.FC<{
  title: string[],
  descriptions: string[],
  imgUrl?: string,
  iconStyle: React.CSSProperties
}> = ({
  title = [],
  descriptions = [],
  imgUrl = '',
  iconStyle = {}
}) => {
  return (
    <div className={clsPrefix}>
      <img src={imgUrl} className={`${clsPrefix}__image`} alt='' />
      <div className={`${clsPrefix}__content`}>
        <div className={`${clsPrefix}__title`}>
          {
            title && title.map((text,index) => (
              <p key={index}>{text}</p>
            ))
          }
        </div>
        <div className={`${clsPrefix}__descriptions`}>
          {
            descriptions && descriptions.map(((item, index) => (
              <DescriptionItem
                key={index}
                content={item}
                className='offc-acc-desc'
                iconName='raw-right'
                iconStyle={iconStyle}
                iconClassName='desc-icon'
              />
            )))
          }
        </div>
      </div>
    </div>
  )
}

export { OfficialAccountCard }
