import React, {
  useEffect,
  useRef,
  useState,
  useReducer,
  ReactNode,
} from "react";
import Icon from "../Icon";
import { diffEndJump } from "../../utils/tools";
import "./index.less";

const clsPrefix = "c-banner-carousel";

interface BannerCarouselProps {
  imgList: Array<{
    img: any;
    url?: string;
    wapUrl?: string | null;
    node?: ReactNode;
  }>;
  speed?: number;
  delay?: number;
  statPage?: string;
  bannerStats?: Array<{
    category: string;
    bizType: number;
  }>;
}

/**
 * 踩坑备注：
 *  1. 挂载到事件或者定时器的函数内有ref或者useState时，函数体内部只能得到初始值，不能获取实时数据。
 *      useState的解决方式是在setState使用回调，其中参数是实时的数据。ref目前好像没有解决方式
 *  2. touch事件要兼容a标签跳转所以每个touch动作不能阻止默认事件，但拖拽时不阻止默认事件可能会有未知BUG，
 *      目前是判断touch位移距离小于3认为是click（不阻止默认事件）。
 *  3. touch不阻止默认事件会触发hover，因此需要改成mouseover
 *
 */

const BannerCarousel: React.FC<BannerCarouselProps> = ({
  imgList = [],
  speed = 1000,
  delay = 500,
  statPage = "",
  bannerStats = [],
}) => {
  const [bannerWidth, setBannerWidth] = useState(0);
  const [translateX, setTranslateX] = useState(0);
  const [transitionDuration, setTransitionDuration] = useState(speed + "ms");
  const [left, setLeft] = useState(0);
  const [isShowBtn, setIsShowBtn] = useState(false);
  const [resize, setResize] = useState(1); // 用于监听resize事件的依赖
  const [time, setTime] = useState({
    num: 0,
    isAdd: true,
  });

  const bannerRef = useRef(); // 轮播图的宽度
  const translateBase = useRef(0); // translateX值的基准
  const checkClick = useRef(false); // 检查是否连续点击
  const intervalRef = useRef();

  const [cur, curDispatch] = useReducer(curIndexReducer, 1);

  useEffect(() => {
    getInitInfo();
    // @ts-ignore
    intervalRef.current = setInterval(() => {
      setTime((state) => {
        if (state.isAdd) {
          return {
            ...state,
            num: state.num + 100,
          };
        } else {
          return { ...state };
        }
      });
    }, 100);
    // @ts-ignore
    bannerRef.current.addEventListener("touchstart", touchStartFn);

    const resizeFn = function() {
      setResize((state) => state + 1);
    };

    window.addEventListener("resize", resizeFn);
    return () => {
      clearInterval(intervalRef.current);
      window.removeEventListener("resize", resizeFn);
    };
  }, []);

  useEffect(() => {
    if (time.num >= delay) {
      curDispatch({ type: "goNext" });
      setTime({
        ...time,
        num: 0,
      });
    }
  }, [time]);

  useEffect(() => {
    getInitInfo();
  }, [resize]);

  function getInitInfo() {
    const bannerInfo =
      // @ts-ignore
      bannerRef.current && bannerRef.current.getBoundingClientRect();
    // @ts-ignore
    setBannerWidth(Number(bannerInfo && bannerInfo.width));
    // @ts-ignore
    setLeft(-Number(bannerInfo && bannerInfo.width));
    translateBase.current = 0;
    curDispatch({ type: "goIndex", index: cur });
  }

  function touchStartFn(e) {
    const touchStart = e.touches[0].pageX;
    let startTranslate = 0;
    let moveX = 0;
    let timeoutId: any = null;
    setTranslateX((state) => {
      startTranslate = state;
      return state;
    });
    setTransitionDuration("0ms");
    setTime((state) => ({
      ...state,
      isAdd: false,
    }));

    const touchMoveFn = (moveEvent) => {
      const touchMove = moveEvent.touches[0].pageX;
      moveX = touchMove - touchStart;

      if (Math.abs(moveX) > 3) {
        moveEvent.preventDefault();
        moveEvent.stopPropagation();
      }

      setTranslateX((state) => startTranslate + moveX);
      e.target.addEventListener("touchend", touchEndFn, false);
    };

    const touchEndFn = (endEvent) => {
      let bannerWidth = 0;
      setBannerWidth((state) => {
        bannerWidth = state;
        return state;
      });

      setTransitionDuration(speed + "ms");

      if (Math.abs(moveX) >= bannerWidth / 2) {
        setTimeout(() => {
          curDispatch({ type: moveX > 0 ? "goPre" : "goNext" });
        }, 100);
      } else if (Math.abs(moveX) > 5) {
        setTranslateX(() => startTranslate);
      }

      if (Math.abs(moveX) > 5) {
        endEvent.preventDefault();
        endEvent.stopPropagation();
      }

      // @ts-ignore
      clearTimeout(timeoutId);

      setTime((state) => ({
        num: 0,
        isAdd: true,
      }));

      e.target.removeEventListener("touchmove", touchMoveFn);
      e.target.removeEventListener("touchend", touchEndFn);
    };

    e.target.addEventListener("touchmove", touchMoveFn, false);

    timeoutId = setTimeout(() => {
      setTime((state) => ({
        ...state,
        isAdd: true,
      }));
      setTransitionDuration(speed + "ms");
    }, delay);
  }

  function goIndex(index: number) {
    setTranslateX(translateBase.current - (index - 1) * bannerWidth);

    if (index === imgList.length + 1) {
      setLeft(left + imgList.length * bannerWidth);
      translateBase.current = translateBase.current - (index - 1) * bannerWidth;
    }

    if (index === 0) {
      setLeft(left - imgList.length * bannerWidth);
      translateBase.current =
        translateBase.current -
        (index - 1) * bannerWidth +
        (imgList.length - 1) * bannerWidth;
    }

    setTime((state) => ({
      ...state,
      num: 0,
    }));
  }

  function curIndexReducer(state: number, action: any) {
    switch (action.type) {
      case "goNext": {
        if (checkClick.current) return state;

        checkClick.current = true;
        let _state = state;
        if (state >= imgList.length + 1) {
          _state = 2;
        } else {
          _state = state + 1;
        }
        goIndex(_state);

        setTimeout(() => {
          checkClick.current = false;
        }, speed / 2);

        return _state;
      }
      case "goPre": {
        if (checkClick.current) return state;
        checkClick.current = true;
        let _state = state;
        if (state <= 0) {
          _state = imgList.length - 1;
        } else {
          _state = state - 1;
        }
        goIndex(_state);

        setTimeout(() => {
          checkClick.current = false;
        }, speed / 2);
        return _state;
      }
      case "goIndex": {
        goIndex(action.index);
        return action.index;
      }
      default: {
        console.error("bannerReducerError");
      }
    }
  }

  function handleClick(
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    btnType: string
  ) {
    e.stopPropagation();
    e.preventDefault();
    curDispatch({ type: btnType });
  }

  function handleImageClick(
    index: number,
    hrefs: { url?: string; wapUrl?: string },
    statPage: string,
    bannerStats
  ) {
    // @ts-ignore
    if (window && window.xqui && window.xqui.useStat && bannerStats[index]) {
      // @ts-ignore
      window.xqui.useStat({
        category: bannerStats[index].category,
        page: statPage,
        action: "click",
      });
    }

    diffEndJump({
      href: hrefs.url,
      wapHref: hrefs.wapUrl,
    });
  }

  return (
    <div className={clsPrefix} ref={bannerRef}>
      <div
        className={`${clsPrefix}__view-window`}
        onMouseOver={() => setIsShowBtn(true)}
        onMouseLeave={() => setIsShowBtn(false)}
        onClick={() => setIsShowBtn(false)} // touch兼容click未阻止冒泡会触发mouseOver，因此需要click后关闭按钮显示
      >
        <div
          className={`${clsPrefix}__item-wrap`}
          style={{
            width: bannerWidth * (imgList.length + 2),
            transitionDuration,
            transform: `translateX(${translateX}px)`,
            left,
          }}
        >
          <div className={`${clsPrefix}__item`} style={{ width: bannerWidth }}>
            {imgList[imgList.length - 1].node ? (
              imgList[imgList.length - 1].node
            ) : (
              <div
                onClick={() =>
                  handleImageClick(
                    imgList.length - 1,
                    {
                      url: imgList[imgList.length - 1].url,
                      wapUrl: imgList[imgList.length - 1].wapUrl,
                    },
                    statPage,
                    bannerStats
                  )
                }
              >
                <img
                  src={imgList[imgList.length - 1].img}
                  className={`${clsPrefix}__item-img`}
                  alt=""
                />
              </div>
            )}
          </div>
          {imgList.map((item, index) => (
            <div
              className={`${clsPrefix}__item`}
              style={{ width: bannerWidth }}
            >
              {item.node ? (
                item.node
              ) : (
                <div
                  onClick={() =>
                    handleImageClick(
                      index,
                      { url: item.url, wapUrl: item.wapUrl },
                      statPage,
                      bannerStats
                    )
                  }
                >
                  <img
                    src={item.img}
                    className={`${clsPrefix}__item-img`}
                    alt=""
                  />
                </div>
              )}
            </div>
          ))}
          <div className={`${clsPrefix}__item`} style={{ width: bannerWidth }}>
            {imgList[0].node ? (
              imgList[0].node
            ) : (
              <div
                onClick={() =>
                  handleImageClick(
                    0,
                    { url: imgList[0].url, wapUrl: imgList[0].wapUrl },
                    statPage,
                    bannerStats
                  )
                }
              >
                <img
                  src={imgList[0].img}
                  className={`${clsPrefix}__item-img`}
                  alt=""
                />
              </div>
            )}
          </div>
        </div>
        {isShowBtn && (
          <span
            className={`${clsPrefix}__btn pre`}
            onClick={(e) => handleClick(e, "goPre")}
          >
            <Icon name="arrow-left" />
          </span>
        )}
        {isShowBtn && (
          <span
            className={`${clsPrefix}__btn next`}
            onClick={(e) => handleClick(e, "goNext")}
          >
            <Icon name="arrow-right" />
          </span>
        )}
        <div
          className={`${clsPrefix}__dot`}
        >
          {imgList.map((item, index) => {
            if (index === 0) {
              return (
                <div
                  className={`${clsPrefix}__dot-item ${
                    cur === index + 1 || cur === imgList.length + 1
                      ? "active"
                      : ""
                  }`}
                  data-index={index}
                  onClick={() =>
                    curDispatch({ type: "goIndex", index: index + 1 })
                  }
                ></div>
              );
            }
            if (index === imgList.length - 1) {
              return (
                <div
                  className={`${clsPrefix}__dot-item ${
                    cur === index + 1 || cur === 0 ? "active" : ""
                  }`}
                  data-index={index}
                  onClick={() =>
                    curDispatch({ type: "goIndex", index: index + 1 })
                  }
                ></div>
              );
            }
            return (
              <div
                className={`${clsPrefix}__dot-item ${
                  cur === index + 1 ? "active" : ""
                }`}
                data-index={index}
                onClick={() =>
                  curDispatch({ type: "goIndex", index: index + 1 })
                }
              ></div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default BannerCarousel;
