import React from 'react'
import KzLogoImage from '../../assets/images/kz-logo.png'
import './index.less'

const KzLogo: React.FC<{}> = () => {
  return (
    <div className='kz-logo'>
      {
       <img src={KzLogoImage} alt='LOGO' className='kz-logo__image' />
      }
    </div>
  )
}

export default KzLogo
