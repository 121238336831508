import React, { useContext } from 'react'
import { DescriptionItem } from '../../../../components/DescriptionItem'
import { CircleIcon } from '../CircleIcon'
import { IsShowContext } from '../../../../components/PanelContainer'
import './index.less'

const clsPrefix = 'c-site-card'
const SiteCard: React.FC<{
  title: string | React.ReactNode,
  descriptions: string[],
  onHover?: () => void,
  onWapClick?: () => void
  index?: number
}> = ({
  title = '',
  descriptions = [],
  onHover = () => null,
  onWapClick = () => null,
  index = 0
}) => {
  const isShow = useContext(IsShowContext)
  return (
    <div className={`hover-grow ${clsPrefix} ${isShow ? 'animated fadeInUp' : ''}`} style={{ animationDuration: `${1.5 + index * 0.3}s` }} onMouseOver={onHover} onClick={onWapClick}>
      <div className={`${clsPrefix}__title`}>{title}</div>
      <div className={`${clsPrefix}__descriptions`}>
        {
          descriptions && descriptions.map(((item, index) => (
            <DescriptionItem
              key={index}
              content={item}
              className='site-desc'
              icon={<CircleIcon />}
              iconClassName='desc-icon'
            />
          )))
        }
      </div>
    </div>
  )
}

export { SiteCard }
