import * as React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { RecoilRoot } from 'recoil';
import Layout from '../../layout/kzLayout';
import KzHompage from './index';

const App: React.FC = () => {
  return (
    <Router>
      <RecoilRoot>
        <Layout>
          <Route path='/' component={KzHompage} />
        </Layout>
      </RecoilRoot>
    </Router>
  )
}

export default App