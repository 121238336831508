import React from 'react'
import { MARKETING_DESCRIPTIONS, btnStats } from '../../../constants/kzHomepage'
import { MarketingCard } from '../components'
import { diffEndJump } from '../../../utils/tools'

const MarketingPanel: React.FC<{
  isInScreen: boolean
}> = ({
  isInScreen = false
}) => {
  return (
    <div className='marketing-panel-children'>
      {
        MARKETING_DESCRIPTIONS.map((item,index) => {
          return (
            <div className='marketing-panel-item'>
              <div className={`marketing-item-card ${isInScreen ? 'animated fadeInUp' : ''}`} style={{ animationDuration: '1.5s' }}>
                <MarketingCard
                  title={item.title}
                  descriptions={item.descriptions}
                  themeColor={item.themeColor}
                  onBtnClick={() => {
                    // @ts-ignore
                    if (window && window.xqui && window.xqui.useStat) {
                      // @ts-ignore
                      window.xqui.useStat({
                        ...btnStats['vote'],
                        page: 'kz_homepage',
                        action: 'click'
                      })
                    }
                    diffEndJump({ href: item.href, wapHref: item.wapHref })
                  }}
                />
              </div>
              <img src={item.imgUrl} alt='' className={`marketing-item-image ${isInScreen ? (index !== 1 ? 'animated fadeInRight' : 'animated fadeInLeft') : ''}`} style={{ animationDuration: '1.5s' }} />
            </div>
          )
        })
      }
    </div>
  )
}

export default MarketingPanel
