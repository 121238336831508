import React from 'react'
import './index.less'
import Icon from "../Icon";

const clsPrefix = 'c-description-item'
const DescriptionItem: React.FC<{
  iconName?: string,
  icon?: React.ReactNode,
  content: string | React.ReactNode,
  className?: string,
  style?: React.CSSProperties,
  iconStyle?: React.CSSProperties,
  iconClassName?: string,
}> = ({
  iconName,
  content,
  className = '',
  style = {},
  icon,
  iconStyle = {},
  iconClassName = ''
}) => {
  return (
    <div className={`${clsPrefix} ${className}`} style={style}>
      <div className={`${clsPrefix}__icon`}>
        { icon || (iconName && <Icon name={iconName} className={iconClassName} style={iconStyle} />)}
      </div>
      <p className={`${clsPrefix}__content`}>{content}</p>
    </div>
  )
}

export { DescriptionItem }
