import React, { useState } from 'react'
import { useRecoilValue } from 'recoil';
import { wapHostValue } from '../../recoil-store';
import { CallingCard, AdvancedLink, Modal, Icon } from '../../../../components'
import { KZ_MORE_SERVICE_LINKS } from '../../../../constants/kzHomepage'
import { getFreeTrial } from '../../../../request/kzHomepage'
import KzWxQrcodeImage from '../../../../assets/images/kz-wx-qrcode.png'
import KzConsultImage from '../../../../assets/images/kz-consult-qrcode.png'
import SuyuchunQrcodeImage from '../../../../assets/images/xiaoya-qywx.png'
import './index.less'

const clsPrefix = 'c-our-infomation'
const OurInfomation: React.FC<{}> = () => {
  const [ phone, setPhone ] = useState<string>()
  const [ isModalShow, setIsModalShow ] = useState<boolean>(false)
  const [ isTrialSuccess, setIsTrialSuccess ] = useState<boolean>(true)
  const wapHost = useRecoilValue(wapHostValue);

  function handleFreeTrial () {
    if (!phone) {
      return
    }
    getFreeTrial(phone).then(res => {
      if (res && res.code === 200) {
        setIsTrialSuccess(true)
        setIsModalShow(true)
        setPhone('')
      } else {
        setIsTrialSuccess(false)
        setIsModalShow(true)
        setPhone('')
      }
    }).catch(err => {
      console.log(err)
      setIsTrialSuccess(false)
      setIsModalShow(true)
      setPhone('')
    })
  }
  return (
    <div className={clsPrefix}>
      <div className={`${clsPrefix}-container`}>
        <CallingCard title='更多服务'>
          <div className={`${clsPrefix}__col`}>
            {
              KZ_MORE_SERVICE_LINKS(wapHost).map((item) => (
                <AdvancedLink {...item} className='info-item' isOpenNewWindow />
              ))
            }
          </div>
        </CallingCard>
        <CallingCard title='在线咨询'>
          <div className={`${clsPrefix}__col`}>
            <div className='info-item'>违法违规信息举报电话 | 免费客服热线</div>
            <a href='tel:400-106-0025' className='info-item'>400-106-0025</a>
            <div className='info-item'>周一至周五 9:30-18:30</div>
            <div className='info-item'>地址：北京市海淀区世纪科贸大厦B座</div>
          </div>
        </CallingCard>
        <CallingCard title='合作微信'>
          <div className={`${clsPrefix}__col`}>
            <div style={{ display: 'flex' }}>
              <div style={{ marginRight: '20px' }}>
                <div style={{ marginBottom: '9px' }}>客服人员</div>
                <div className='qrcode-container'>
                  <img src={KzConsultImage} />
                </div>
              </div>
              <div>
                <div style={{ marginBottom: '9px' }}>微信公众号</div>
                <div className='qrcode-container'>
                  <img src={KzWxQrcodeImage} />
                </div>
              </div>
            </div>
            <div className='info-item' style={{ marginTop: '12px' }}>商务合作：<a className='info-item' href='malito:suyuchun@icloudfuture.com'>suyuchun@icloudfuture.com</a></div>
          </div>
        </CallingCard>
        <CallingCard title='免费试用'>
          <div className={`${clsPrefix}__col`}>
            <div className='free-trial' >
              {/* @ts-ignore */}
              <input className='free-trial__input' placeholder='请输入手机号' value={phone} onChange={event => setPhone(event.target.value)} />
              <button className='free-trial__button' onClick={handleFreeTrial}>试用</button>
            </div>
          </div>
        </CallingCard>
      </div>
      <Modal
        visible={isModalShow}
        onClose={() => setIsModalShow(false)}
        className='free-trial-prompt'
        wrapperStyle={{ width: '450px' }}
        contentStyle={{ height: '250px' }}
        headerStyle={{ padding: '24px 10px', border: 'none' }}
      >
        <div className='free-trial-prompt__children'>
          <p style={{ display: 'flex', alignItems: 'center' }}>
            <Icon name={isTrialSuccess ? 'success' : 'error'} style={{ color: `${ isTrialSuccess ? '#52c41a' : '#db3b21'}`, fontSize: '20px', marginRight: '10px' }} />
            <span style={{ fontSize: '18px', fontWeight: 'bold' }}>提交{isTrialSuccess ? '成功' : '失败，请稍候再试'}</span>
          </p>
          <p style={{ color: '#a9a9a9', marginTop: '16px' }}>如果您想享受更多优惠和专业的一对一的服务</p>
          <p style={{ color: '#a9a9a9', marginBottom: '6px' }}>请您添加我们的专属客服</p>
          <div className='qrcode-container'>
            <img src={SuyuchunQrcodeImage} />
          </div>
        </div>
      </Modal>
    </div>
  )
}
export { OurInfomation }
