import React from 'react'
import './index.less'

const clsPrefix = 'c-circle-icon'
const CircleIcon: React.FC<{
  color?: string
}> = ({
  color = '#207CD3'
}) => (
  <div className={clsPrefix} style={{ border: `3px solid ${color}` }} />
)
export { CircleIcon }
