import React from 'react'
import { DescriptionItem } from '../../../../components/DescriptionItem'
import { CircleIcon } from '../CircleIcon'
import { Button } from '../../../../components'
import './index.less'

const clsPrefix = 'c-marketing-card'
const MarketingCard: React.FC<{
  title: string | React.ReactNode,
  descriptions: string[],
  themeColor?: string,
  btnText?: string,
  onBtnClick?: () => void
}> = ({
  title = '',
  descriptions = [],
  themeColor = '#F79241',
  btnText = '立即使用',
  onBtnClick = () => null
}) => {
  return (
    <div className={clsPrefix}>
      <div className={`${clsPrefix}__text-content`}>
        <div className={`${clsPrefix}__title`}>{title}</div>
        <div className={`${clsPrefix}__descriptions`}>
          {
            descriptions && descriptions.map(((item, index) => (
              <DescriptionItem
                key={index}
                content={item}
                className='marketing-desc'
                icon={<CircleIcon color={themeColor} />}
              />
            )))
          }
        </div>
        <Button
          type='primary'
          themeColor={themeColor}
          onClick={onBtnClick}
          className='marketing-btn'
        >
          {btnText}
        </Button>
      </div>
    </div>
  )
}

export { MarketingCard }
